import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { BaseToken, HiddenToken, GeneratedToken } from '@/types/tokens.types';
import * as TokensAPI from '@/api/tokens.api';

@Module({ name: 'tokens', store, dynamic: true })
class Tokens extends VuexModule {
  public tokens: HiddenToken[] = [];
  public tokensRecord: Record<string, HiddenToken> = {};
  public hasLoadedTokens = false;

  @Mutation
  public setTokens(tokens: HiddenToken[]): void {
    this.tokens = tokens;
  }

  @Mutation
  public setTokensRecord(tokens: HiddenToken[]): void {
    tokens.forEach((token: HiddenToken) => {
      this.tokensRecord[token.patId] = token;
    });
    this.hasLoadedTokens = true;
  }

  @Action
  public async getAllTokens(): Promise<void> {
    const tokenResponse = await TokensAPI.getAllTokens();
    this.setTokens(tokenResponse);
    this.setTokensRecord(tokenResponse);
  }

  @Action({ rawError: true })
  public async deleteToken(tokenId: string): Promise<boolean> {
    return TokensAPI.deleteToken(tokenId);
  }

  @Action
  public async generateToken(token: BaseToken): Promise<GeneratedToken> {
    return TokensAPI.generateToken(token);
  }

  @Action({ rawError: true })
  public async updateToken(token: HiddenToken): Promise<HiddenToken> {
    return TokensAPI.updateToken(token);
  }

  @Action
  public async tokenById(tokenId: string): Promise<HiddenToken> {
    if (this.tokens.length === 0) {
      await this.getAllTokens();
    }
    return this.tokensRecord[tokenId];
  }
}

export const TokensModule = getModule(Tokens);
