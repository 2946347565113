
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isSelectOptions, SelectValue, SelectOption } from '@/components/form/form.types';
import { randId } from '@/utils/components';
@Component
export default class GRadioGroupThree extends Vue {
  @Prop({ type: String, required: true }) readonly value!: SelectValue;
  @Prop({ type: Array, required: true }) readonly options!: SelectOption[] | string[];
  @Prop({ type: String, default: 'r' }) readonly name!: string;
  @Prop(Boolean) readonly inline!: boolean;
  /**
   * @deprecated
   */
  get randName() {
    return `${name}_${randId()}`;
  }
  isChecked(option: SelectOption) {
    return option.value === this.value;
  }
  /**
   * workaround for not being able to add options after render
   */
  get key() {
    return JSON.stringify(this.options);
  }
  get formattedOptions(): SelectOption[] {
    if (!isSelectOptions(this.options)) {
      return this.options.map(option => {
        return {
          key: option,
          value: option,
          label: option,
          disabled: false,
        };
      });
    }
    return this.options.map(option => {
      const out = { ...option };
      if (!out.key) {
        out.key = out.value;
      }
      if (!out.label) {
        out.label = out.value;
      }
      return out;
    });
  }
  get eventHandlers() {
    return {
      change: this.input,
      blur: (e: Event) => this.$emit('blur', e),
    };
  }
  // currently gsk-radio-group emits 3 "change" events...
  input(e: CustomEvent): void {
    if (e.detail === undefined) {
      // skip the native event that does not have e.detail set
      return;
    }
    let {
      detail: { value },
    } = e;
    if (Array.isArray(value)) {
      value = value[0];
    }
    const opt = this.formattedOptions.find(option => value === option.value);
    if (opt === undefined) {
      const err = new Error(
        'GRadioGroup.options does not contain the value emitted from the radio group',
      );
      this.$log('error', this.options, e.detail);
      throw err;
    }
    this.$emit('input', opt.value);
    this.$emit('change', opt.value);
  }
  created() {
    const opt = this.formattedOptions.find(option => this.value === option.value);
    if (opt === undefined) {
      if (this.formattedOptions.length) {
        this.$emit('input', this.formattedOptions[0].value);
      }
    }
  }
}
