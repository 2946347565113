import axios from '@/api/service';
import URLs from '@/api/service/urls';
import { BaseToken, GeneratedToken, HiddenToken } from '@/types/tokens.types';

export async function getAllTokens(): Promise<HiddenToken[]> {
  const { data } = await axios.get<HiddenToken[]>(URLs.AllTokens, {});
  return data;
}

export async function deleteToken(TokenId: string): Promise<boolean> {
  const { data } = await axios.delete<boolean>(URLs.DeleteToken, { params: { id: TokenId } });
  return data;
}

export async function generateToken(newToken: BaseToken): Promise<GeneratedToken> {
  const { data } = await axios.post<GeneratedToken>(URLs.GenerateTokens, newToken);
  return data;
}

export async function updateToken(updateToken: HiddenToken): Promise<HiddenToken> {
  const { patId, ...baseToken } = updateToken;
  const { data } = await axios.put<HiddenToken>(URLs.UpdateToken, baseToken, {
    params: { id: patId },
  });
  return data;
}
