
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { RawLocation } from 'vue-router';
import dayjs from 'dayjs';
import FullScreenForm from '@/components/FullScreenForm.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import Form from '@/components/mixins/formChecks';
import { RouteNames, TextfieldInfo } from '@/constants';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import GRadioGroupThree from '@/Rpa/components/gsk-components/GskRadioGroupThree.vue';
import { openSnackbar } from '@/utils/components';
import { TokensModule } from '@/store/modules/tokens.module';
import { BaseToken } from '@/types/tokens.types';
import CopyCode from '@/components/CopyCode.vue';

@Component({
  components: {
    CopyCode,
    FullScreenForm,
    GButton,
    GTextfield,
    GDialog,
    GRadioGroupThree,
  },
})
export default class NewTokenView extends mixins(Form) {
  private loading = false;
  private dialogOpen = false;
  private tokenDescription = '';
  private expire = '6';
  private generatedToken = '';
  public descriptionLabel: string = TextfieldInfo.descriptionLabel;
  public tokenDescriptionPlaceholder: string = TextfieldInfo.tokenDescriptionPlaceholder;
  public tokenDescriptionValidationMessage: string =
    TextfieldInfo.tokenDescriptionValidationMessage;

  async createToken(): Promise<void> {
    try {
      const newToken = await TokensModule.generateToken(this.token);
      this.generatedToken = newToken.token;
      this.dialogOpen = true;
      openSnackbar.call(this, 'New Token Created');
    } catch (err: any) {
      openSnackbar.call(this, err.message, { type: 'error' });
    }
  }

  async copied(): Promise<void> {
    await this.$router.push({ name: RouteNames.MyTokens });
  }

  get checkExpire(): string {
    return this.expire.toString();
  }

  set checkExpire(checked: string) {
    this.expire = checked;
  }

  get expirePeriods() {
    return [
      {
        label: 'One Month',
        value: '1',
        checked: false,
      },
      {
        label: 'Three Months',
        value: '3',
        checked: false,
      },
      {
        label: 'Six Months',
        value: '6',
        checked: true,
      },
    ];
  }

  get token(): BaseToken {
    return {
      description: this.tokenDescription,
      expireDate: dayjs().add(Number(this.expire), 'month').format(),
    };
  }

  get canCreate(): boolean {
    return !!this.tokenDescription && !!this.expire;
  }

  get closeRoute(): RawLocation {
    return {
      name: RouteNames.MyTokens,
    };
  }

  get disabled(): boolean {
    return this.loading;
  }
}
